import React from "react"

import * as entrepreneurialStyles from "../entrepreneurial/entrepreneurial.module.scss"

import CenterLayout from "../center_layout/index"

import { graphql, useStaticQuery } from "gatsby"

const EntrepreneurialGrowth = () => {
  const data = useStaticQuery(graphql`
    query {
      entrepreneurialGrowthContentYaml {
        entrepreneurial {
          title
          description
        }
      }
    }
  `)
  return (
    <div className={entrepreneurialStyles.container}>
      <CenterLayout>
        <div
          data-sal="slide-up"
          data-sal-duration="1000"
          data-sal-delay="5"
          data-sal-easing="ease"
        >
          <h1>{data.entrepreneurialGrowthContentYaml.entrepreneurial.title}</h1>
          <p>
            {data.entrepreneurialGrowthContentYaml.entrepreneurial.description}
          </p>
        </div>
      </CenterLayout>
    </div>
  )
}

export default EntrepreneurialGrowth
