import React from "react";

import LeftLayout from "../left_layout/index";
import * as processStyles from "./process.module.scss";

import { graphql, useStaticQuery } from "gatsby";

const Process = () => {
  const data = useStaticQuery(graphql`
    query {
      processContentYaml {
        process {
          title
          description
        }
      }
    }
  `);
  return (
    <div className={processStyles.container}>
      <LeftLayout>
        <div
          data-sal="slide-up"
          data-sal-duration="1000"
          data-sal-delay="5"
          data-sal-easing="ease"
        >
          <h1>{data.processContentYaml.process.title}</h1>
          {data.processContentYaml.process.description.map(item => (
            <p key={item}>{item}</p>
          ))}
        </div>
      </LeftLayout>
    </div>
  );
};

export default Process;
