import React from "react"

import CenterLayout from "../center_layout/index"

import * as foundersStyles from "./founders.module.scss"

import { graphql, useStaticQuery } from "gatsby"

const Founders = () => {
  // Retrieving data
  const data = useStaticQuery(graphql`
    query {
      foundersContentYaml {
        founders {
          title
          description
        }
      }
    }
  `)

  return (
    <div className={foundersStyles.container}>
      <CenterLayout>
        <div
          data-sal="slide-up"
          data-sal-duration="1000"
          data-sal-delay="5"
          data-sal-easing="ease"
          style={{ marginTop: "30vh" }}
        >
          <h1>{data.foundersContentYaml.founders.title}</h1>
          <p>{data.foundersContentYaml.founders.description[0]}</p>
          <p>{data.foundersContentYaml.founders.description[1]}</p>
        </div>
      </CenterLayout>
    </div>
  )
}
export default Founders
