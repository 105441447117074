import React from "react"
import * as logoStyles from "./logo.module.scss"

import { Link } from "gatsby"

const Logo = () => {
  return (
    <Link to="/">
      <div className={logoStyles.container}>
        <svg
          className={logoStyles.logo_svg}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 539.39 135.32"
        >
          <defs>
            <style>
              {"\
        .cls-1{\
          fill:#fff;\
        }\
      "}
            </style>
          </defs>
          <g id="Layer_2" data-name="Layer 2">
            <g id="LOGO">
              <path
                className="cls-1"
                d="M238.8,128c0-1.8,1.6-2.2,7-2.8,7-.8,7.2-3.8,7.2-12,0-22.6-1.6-66.2-2.4-88.4-.2-2.8-.2-3.8-.8-3.8-.8,0-1,1-1.8,2.8-4,10.2-10.2,29.8-25.4,78.2-6.6,22.4-5.6,27.2-8.6,27.2-1.2,0-2-2.2-3-6.6-6-21.6-24.4-81.8-30-98.8q-.6-2.4-1.8-2.4c-.8,0-.8,1.6-1.2,4.6-1.4,15.4-2.6,51.6-2.6,69.6,0,14.6-.4,28.6,6.8,29.6,3,.4,9,.2,9,2.8,0,1.4-1,2.6-3.8,2.6-3.8,0-6.2-.8-14.8-.8-7.8,0-11.2.8-13.4.8-1.8,0-3.2,0-3.2-2.4,0-2.8,3.2-2.2,6.4-3,6.4-1.4,6.6-7,7.2-17.6,1.2-19,3-68,3-83.8.2-11.4,0-13.6-6.2-14.2-3.8-.4-7.4-.8-7.4-3.2,0-2.2,2-2.4,4.2-2.4,3.4,0,8.4.6,11.8.6,2.6,0,7.6-.6,10-.6,2.2,0,2.8.8,3.6,3.2,8.4,26.8,17,53.8,25.2,80.6,1.8,5.2,2.8,10.6,4.4,10.6,1.2,0,2.2-2.8,4-8.6,8.4-27,16.6-53.6,25-80.4,1.2-4.2,2.4-5.4,4.4-5.4,2.2,0,6.4.6,9.2.6,3,0,8.2-.6,11.6-.6,1.8,0,3.2.8,3.2,2.2,0,2.4-2.6,3-8.6,3.4-2.8.2-3.6,2.8-3.6,12.8,0,16.8,2.4,68.4,3.2,91.8,0,7.4,1.8,10.4,6,11,4.4.8,6.6,1,6.6,2.8,0,2.2-1.4,2.6-3.6,2.6-2.6,0-6.4-.8-15.6-.8-7.8,0-14.2.8-17.4.8-2.6,0-3.8-.8-3.8-2.4Z"
              />
              <path
                className="cls-1"
                d="M324.6,56.58c0-10.4-2.2-14.6-9.2-14.6-11,0-16,8-19.2,18.8a19.19,19.19,0,0,1-4.6,7.4,12.9,12.9,0,0,1-7.8,3c-3,0-4-1.2-4-4.2,0-10.8,17-29.6,40.4-29.6,6.4,0,11,.8,13.8,3.8,2.2,2.2,3,5.4,3,11v60c0,5.4,1.6,9.4,5.8,9.4a6.55,6.55,0,0,0,6.4-4.2c.8-2,1.6-4.2,2.8-4.2,1,0,1.6.8,1.6,3,0,3.6-5.6,15.2-16.6,15.2-8.2,0-9.8-7-11-11.2-.6-1.6-1-2.8-2-2.8-1.4,0-4.4,4.2-9.4,8a29.15,29.15,0,0,1-18.8,6.8c-8.4,0-15.4-6-15.4-17.2,0-10.6,8-17,22.6-27,6.8-4.8,11.8-7.8,15.2-10.4,5.4-3.6,6.4-5.2,6.4-10ZM294,112c0,7.8,4.8,11.6,10.6,11.6,5.2,0,9.8-2.8,14.2-7,4.6-4.2,5.8-7.2,5.8-13V83.38c0-2.4-.2-3.2-1.2-3.2s-2.2.4-5.2,2.4a99.64,99.64,0,0,0-13,9.6c-6.6,5.6-11.2,11.8-11.2,19.6Z"
              />
              <path
                className="cls-1"
                d="M497,62.78c0-8.2-1.8-9.6-7.8-9.6-1.8,0-2.6-.4-2.6-1.6,0-2,1.6-2.6,3.6-3.2a25.48,25.48,0,0,0,11.2-6.8c2.6-2.8,3.4-4.2,5-4.2,1,0,1.4,1,1.6,3,.2,2.2.2,5.4.2,8.8,0,3.2,0,5.4.8,5.4,1.6,0,3-4.4,6-8.4,3-4.2,7-8.6,13.8-8.6,6.6,0,10.6,4.8,10.6,12.6,0,6.2-2.4,10.4-7.6,10.4-5,0-6.6-3.2-7-7.2-.2-3-.6-6-2.8-6-4.2,0-7.4,4.6-9.4,9.8a40,40,0,0,0-3.2,15.4v39.8c0,9.4.2,12.4,7.6,13.4,4,.6,6.6,1,6.6,2.6,0,1.8-1,2.2-4.4,2.2-2.2,0-5-.8-16-.8-8.8,0-11.8.8-14,.8s-3.6-.6-3.6-2c0-2.2,1.8-2.4,4.4-2.8,6.2-.6,7-2.4,7-11.8Z"
              />
              <path
                className="cls-1"
                d="M483.59,106.38c-1.4,0-2,2.8-4,6.6-3,6.6-9.2,10.8-18.8,10.8-15.8,0-26.2-16-26.2-39.6,0-3.2,1.2-4.2,5.2-4.2h40.8c4.4,0,4.4-.8,4.4-10.8,0-19.2-11-31.8-27.2-31.8-17.6,0-36.79,15.4-36.79,49.2,0,10.3,2.07,19.41,5.91,26.74a3,3,0,0,0-.71-.07c-1.4,0-2.6,2.8-4.4,5.4s-4.4,5.4-8.2,5.4c-5,0-7-3.6-7-10.8V48.87c0-3.4.6-3.8,3.6-3.8h10c3.8,0,4.8-1,4.8-3.6,0-2.4-1.2-3-4.4-3h-10c-3,0-4-1.2-4-4.4v-16c0-3.8-.4-4.4-1.8-4.4-1.2,0-1.4,1-3,4.2-5.4,10.4-9.95,20.62-18.36,20.62h-9.56c-3,0-4-1.2-4-4.4v-16c0-3.8-.4-4.4-1.8-4.4-1.2,0-1.4,1-3,4.2a81.66,81.66,0,0,1-14,20c-2.2,2.4-3.4,3.8-3.4,4.8,0,2.2,1.61,2.59,3.4,2.4a10.18,10.18,0,0,1,4.4.2c1.4.4,2.2,1,2.2,3v64.4c0,7.4,1,12,4,15.4,2.8,2.8,6.8,4.2,11.8,4.2,14.2,0,17.8-15.6,17.8-17.4,0-1.2-.6-1.6-1.8-1.6-1.4,0-2.6,2.8-4.4,5.4s-4.4,5.4-8.2,5.4c-5,0-7-3.6-7-10.8V48.87c0-3.4.6-3.8,3.6-3.8h13l1.32,0a27.94,27.94,0,0,1,4.4.2c1.4.39,2.2,1,2.2,3v64.4c0,7.4,1,12,4,15.4,2.8,2.8,6.8,4.2,11.8,4.2,13.52,0,17.42-14.14,17.77-17,6.28,10.6,16.56,17,29.82,17A27.59,27.59,0,0,0,482,118c1.2-2.4,3-6.2,3-9.2C485,107,484.39,106.38,483.59,106.38Zm-48.4-36c0-14.6,8.6-28.4,21.2-28.4,10.8,0,16.6,9.2,16.6,21.2,0,5.2-1.2,7.4-3.6,8.8-2,1-7.6,1.8-14.4,2.2-3.4.2-7.2.4-11.4.4-7.8,0-8.4-.8-8.4-4Z"
              />
              <path
                className="cls-1"
                d="M122,14.24l-.31-.56-.55-.31C120.11,12.82,96.81,0,67.66,0S15.21,12.82,14.24,13.37l-.56.31-.31.56C12.82,15.21,0,38.51,0,67.66s12.82,52.45,13.37,53.43l.31.55.56.31c1,.55,24.27,13.37,53.42,13.37S120.11,122.5,121.09,122l.55-.31.31-.55c.55-1,13.37-24.28,13.37-53.43S122.5,15.21,122,14.24Zm-3.62,104.09c-3.71,1.93-25.12,12.49-50.67,12.49S20.71,120.26,17,118.33C15.06,114.61,4.5,93.17,4.5,67.66S15.06,20.71,17,17C20.71,15.06,42.15,4.5,67.66,4.5s47,10.56,50.67,12.5c1.93,3.71,12.49,25.15,12.49,50.66S120.26,114.62,118.33,118.33Z"
              />
            </g>
          </g>
        </svg>
      </div>
    </Link>
  )
}

export default Logo
