import React from "react"
import {useEffect} from 'react'


import NavBar from "../components/nav_bar/index"
import Form from "../components/form/index"
import Footer from "../components/footer/index"
import Header from "../components/header/index"
// import Testimonial from "../components/testimonial/index"
import Founders from "../components/founders/index"
import Brands from "../components/brands/index"
import Process from "../components/process/index"
import AIDrivenActions from '../components/AI_driven_actions/index'
import EntrepreneurialGrowth from '../components/entrepreneurial/index'
import EdgeOfPossible from '../components/edge_of_possible/index'


import "../styles/index.scss"

const HomePage = () => {

  useEffect(() => {
  }, [])
  return (
    <div className="home_main">
      <NavBar />
      <Header/>
      <Brands />
      <Founders />
      <Process />
      <AIDrivenActions/>
      {/* <Testimonial /> */}
      <EntrepreneurialGrowth/>
      <EdgeOfPossible/>
      {/* <Form/> */}
      <Footer />
    </div>
  )
}
export default HomePage
