import React from 'react'

import * as centerLayout from './center_layout.module.scss'

const CenterLayout = ({children}) =>{
    return(
        <div className={centerLayout.container}>
            {children}
        </div>
    )
}

export default CenterLayout;