import React from 'react'

import { Link } from 'gatsby'
import * as footerStyles from './footer.module.scss'

const Footer = () => {
    return(
        <div className={footerStyles.container}>
            <p className={footerStyles.left_txt}>Confidential. Copyright © 2021 Matter.</p>
            <p><Link className={footerStyles.link} to="/privacy_policy">Privacy Policy</Link></p>
        </div>
    )
}

export default Footer;