import React from "react";

import * as logoBarStyles from "./logo_bar.module.scss";

import butcherBoxLogo from "../../../static/assets/logos/butcherBox_logo.png";
import keytoLogo from "../../../static/assets/logos/keyto_logo.png";
import dryWinesLogo from "../../../static/assets/logos/dryWines_logo.png";
import trackRLogo from "../../../static/assets/logos/trackR_logo.png";
import paleoValeyLogo from "../../../static/assets/logos/paleoValey_logo.png";
import nativePathLogo from "../../../static/assets/logos/nativePath_logo.png";
import herbalyLogo from "../../../static/assets/logos/herbaly_logo.png";
import VGBLogo from "../../../static/assets/logos/VGB_logo.png";
import goldilocksLogo from "../../../static/assets/logos/WHITE_GOLDIOCKS.jpg";

const LogosBar = () => {
  return (
    <div className={logoBarStyles.container}>
      <img alt="Dry Farm Wines" width="120px" src={dryWinesLogo} />
      <img alt="Herbaly" width="120px" src={herbalyLogo} />
      <img alt="Very good butchers" width="120px" src={VGBLogo} />
      <img alt="Butcher box" width="120px" src={butcherBoxLogo} />
      <img alt="Keyto" width="120px" src={keytoLogo} />
      <img alt="TrackR" width="120px" src={trackRLogo} />
      <img alt="Paleovalley" width="120px" src={paleoValeyLogo} />
      <img alt="Nativr path" width="120px" src={nativePathLogo} />
      <img alt="Goldilocks" width="120px" src={goldilocksLogo} />
    </div>
  );
};
export default LogosBar;
