import React from 'react'

import * as buttonStyles from './button.module.scss'

const Button = (props) => {

    //Handle the button click
    return(
        <div className={buttonStyles.button}>
            <button type="submit" onClick={props.onSubmit}>{props.text}</button>
        </div>
    )
}

export default Button;