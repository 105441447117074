import React from 'react'
import * as leftLayoutStyles from './left_layout.module.scss'

const LeftLayout = ({children}) => {
    return(
        <div className={leftLayoutStyles.container}>
            <div className={leftLayoutStyles.wrapper}>
            {children}
            </div>
        </div>
    )
}

export default LeftLayout;