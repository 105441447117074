import React from "react"

import Button from "../button/index"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown } from "@fortawesome/free-solid-svg-icons"
import EarthOrbiting from "../../../static/assets/background_images/header.mp4"

import * as headerStyles from "./header.module.scss"

import { graphql, useStaticQuery } from "gatsby"


const Header = () => {
  // Retreiving data
  const data = useStaticQuery(graphql`
    query {
      headerContentYaml {
        header {
          title
          description
        }
      }
    }
  `)
  const onSubmit = () => {
    window.scrollTo(0, document.body.scrollHeight)
  }
  return (
    <div className={headerStyles.header_wrapper}>
      <video loop muted autoPlay>
        <source src={EarthOrbiting} type="video/mp4" />
      </video>
      <div className={headerStyles.container}>
        <div className={headerStyles.text_wrapper}>
          <h1>{data.headerContentYaml.header.title}</h1>
          <p>
            {data.headerContentYaml.header.description}
          </p>
        </div>
        <Button text={"Find Out How We Can Help"} onSubmit={onSubmit} />
        <div className={headerStyles.bounce}>
          <FontAwesomeIcon icon={faChevronDown} color={"#808080"} />
        </div>
      </div>
    </div>
  )
}
export default Header
