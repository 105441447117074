import React from "react"
import * as brnadsStyles from "./brands.module.scss"

import LeftLayout from "../left_layout/index"
import LogoBar from "../logo_bar/logosBar"

import { graphql, useStaticQuery } from "gatsby"

const Brands = () => {
  const data = useStaticQuery(graphql`
    query {
      brandsContentYaml {
        brands {
          title
          description
        }
      }
    }
  `)
  return (
    <div className={brnadsStyles.container}>
      <LeftLayout>
        <div
          data-sal="slide-up"
          data-sal-duration="1000"
          data-sal-delay="5"
          data-sal-easing="ease"
        >
          <h1>{data.brandsContentYaml.brands.title}</h1>
          <p>{data.brandsContentYaml.brands.description}</p>
        </div>
      </LeftLayout>
      <LogoBar />
    </div>
  )
}
export default Brands
