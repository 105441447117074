import React from 'react'
import Logo from '../logo/index'
import * as navBarStyles from './navBar.module.scss'

const NavBar = () => {

    return(
        <div className={navBarStyles.container}>
            <Logo/>
        </div>
    )
}
export default NavBar;